import React, { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Hooks
import useInterval from "../hooks/useInterval"
import useTranslation from "../hooks/useTranslation"

// Components
import SectionTitle from "../components/section-title"

// Logos
import BNRLogo from "../assets/logos/bnr.svg"
import VisaLogo from "../assets/logos/visa.svg"
import MastercardLogo from "../assets/logos/mastercard.svg"

// Styles
import "../styles/pages/product.scss"

const HeroSection = ({ imageFluid }) => {
  const { t } = useTranslation()
  return (
    <section className="section is-hero has-margin-bottom-6">
      <div className="container">
        <div className="columns">
          <div className="column is-5">
            <h1 className="title main-title has-text-white is-spaced is-2 ">
              {t("Twispay Benefits")}
            </h1>
            <h2 className="subtitle main-subtitle has-text-white has-margin-bottom-6">
              {t(
                "A comprehensive set of tools enabling you to start processing payments right away"
              )}
            </h2>
          </div>
          <div className="column">
            <Img fluid={imageFluid} />
          </div>
        </div>
      </div>
    </section>
  )
}

const SecureSection = ({ imageFluid }) => {
  const { t } = useTranslation()
  return (
    <section className="section ">
      <div className="container">
        <SectionTitle
          title={t("Secure Payment Gateway")}
          subtitle={t(
            "Powered by a nifty API, easy to integrate and extremely developer friendly"
          )}
        />
        <div className="columns is-vcentered">
          <div className="column is-three-fifths">
            <Img fluid={imageFluid} />
          </div>
          <div className="column">
            <p className="is-size-5">
              {t(
                "At the core of our services lies our merchants payment gateway, a flexible and secure, PCI DSS compliant platform connecting you as our merchant to a myriad of payment providers or acquierers throughout Europe, enabling you to process payments in any currency from customers across the globe, through virtually any payment method."
              )}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

const DashboardSection = ({ imagesFluid }) => {
  const { t } = useTranslation()
  const [visibleImageInd, setVisibleImageInd] = useState(0)
  useInterval(() => {
    setVisibleImageInd((visibleImageInd + 1) % 3)
  }, 3000)
  return (
    <section className="section dashboard-section has-margin-bottom-7">
      <div className="container">
        <SectionTitle
          title={t("Powerful Transaction Dashboard")}
          subtitle={t(
            "Staying in charge of your finances is as critical as making sure your website runs properly"
          )}
        />
        <div className="columns is-vcentered">
          <div className="column">
            <p className="is-size-5">
              {t(
                "With Twispay's transaction dashboard you have a complete overview of all your important performance indicators. Real-time view over data such as number of orders and transactions or live revenue monitoring make our reporting a meaningful way of analysing and predicting your business health metrics."
              )}
            </p>
          </div>
          <div className="column is-three-fifths">
            <div className="is-perspective">
              <div className="image-wrapper">
                <AnimatePresence>
                  <motion.div
                    key={visibleImageInd}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 1 }}
                    transition={{
                      opacity: { duration: 0.3 },
                    }}
                    className="motion-wrapper"
                  >
                    <Img fluid={imagesFluid[visibleImageInd]} />
                  </motion.div>
                </AnimatePresence>
                {/* dummy for maintaining height */}
                <Img style={{ opacity: 0 }} fluid={imagesFluid[0]} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const CheckoutSection = ({ imageFluid }) => {
  const { t } = useTranslation()
  return (
    <section className="section ">
      <div className="container">
        <SectionTitle
          title={t("Your Custom Checkout")}
          subtitle={t(
            "This is where the power of a flexible system meets the beauty of personalised user experiences"
          )}
        />
        <div className="columns is-vcentered">
          <div className="column is-three-fifths">
            <Img fluid={imageFluid} />
          </div>
          <div className="column">
            <p className="is-size-5">
              {t(
                "Twispay has been built from the ground up with a keen focus on customization and templating. Whether you would like to completely design your payment page through the use of next-generation templating, or you only want to brand the page with your logo, custom colours and fields, Twispay has got you covered."
              )}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

const CreditsSection = ({ imageFluid }) => {
  const { t } = useTranslation()
  const items = [
    {
      logo: BNRLogo,
      title: t("Authorised by the National Bank"),
      subtitle: t(
        "Governed and regulated by European law enforced by the National Bank of Romania"
      ),
    },
    {
      logo: MastercardLogo,
      title: t("Principal Member of Mastercard"),
      subtitle: t(
        "Blazing fast onboarding and super competitive rates on the acquiring side"
      ),
    },
    {
      logo: VisaLogo,
      title: t("Principal Member of Visa"),
      subtitle: t(
        "Process the most popular payment methods from customers worldwide"
      ),
    },
  ]
  return (
    <section className="section credits-section has-margin-bottom-7">
      <div className="container">
        <SectionTitle
          title={t("Credits")}
          subtitle={t(
            "Twispay is a financial institution that offers online payment processing and acquiring services."
          )}
        />
        <div className="columns is-vcentered">
          <div className="column">
            {items.map(item => (
              <div key={item.title} className="credit-item has-margin-bottom-6">
                <div className="has-margin-right-5">
                  <img src={item.logo} alt="National Bank of Romania" />
                </div>
                <div>
                  <h4 className="title is-5">{item.title}</h4>
                  <p className="subtitle is-6">{item.subtitle}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="column">
            <Img fluid={imageFluid} />
          </div>
        </div>
      </div>
    </section>
  )
}

const FraudSection = ({ imageFluid }) => {
  const { t } = useTranslation()
  return (
    <section className="section ">
      <div className="container">
        <SectionTitle
          title={t("Powerful Fraud Protection")}
          subtitle={t(
            "Focus on your business while we protect you from online fraudulent activities for your complete peace of mind"
          )}
        />
        <div className="columns is-vcentered">
          <div className="column ">
            <Img fluid={imageFluid} />
          </div>
          <div className="column">
            <p className="is-size-5">
              {t(
                "Twispay provides a state-of-the-art Fraud Monitoring and Alert System that employs unbeatable fraud detection algorithms, 24/7 monitoring and other tools such as smart scoring, automatic thresholds and blacklisting."
              )}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

const ProductPage = ({ data: images }) => {
  const { t } = useTranslation()
  return (
    <Layout stripeClass="is-purple-violet" pageName="product">
      <SEO title={t("Twispay Product Features")} />
      <HeroSection imageFluid={images.HeroImage.childImageSharp.fluid} />
      <SecureSection imageFluid={images.SecureImage.childImageSharp.fluid} />
      <DashboardSection
        imagesFluid={[
          images.DashboardImage1.childImageSharp.fluid,
          images.DashboardImage2.childImageSharp.fluid,
          images.DashboardImage3.childImageSharp.fluid,
        ]}
      />
      <CheckoutSection
        imageFluid={images.CheckoutImage.childImageSharp.fluid}
      />
      <CreditsSection imageFluid={images.CreditsImage.childImageSharp.fluid} />
      <FraudSection imageFluid={images.FraudImage.childImageSharp.fluid} />
    </Layout>
  )
}

export const images = graphql`
  query {
    HeroImage: file(relativePath: { eq: "product-hero.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    SecureImage: file(relativePath: { eq: "secure-gateway.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    DashboardImage1: file(relativePath: { eq: "product-dashboard-1.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    DashboardImage2: file(relativePath: { eq: "product-dashboard-2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    DashboardImage3: file(relativePath: { eq: "product-dashboard-3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CheckoutImage: file(relativePath: { eq: "product-custom-checkout.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CreditsImage: file(relativePath: { eq: "product-credits.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    FraudImage: file(relativePath: { eq: "product-fraud.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default ProductPage
